import React from "react";
import styled, { css } from "styled-components";
import { desktop, mobile } from "../libs/viewport";
//import homework from "../assets/homework.jpg";
import homework from "../assets/2021introduce.png";

const Wrap = styled.div`
  padding: 8% 5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  position: relative;
  flex-wrap: wrap;
`;
const Flex = styled.div`
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: center;
  justify-content: space-between;
  align-items: flex-start;
`;
const Title = styled.div`
  width: fit-content;
  font-family: 'NanumSquareEB';
  font-size: 50px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.73px;
  color: #212121;
  display: inline-block;
  position: relative;
  &::before {
    content: "";
    height: 33px;
    z-index: -15;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 193, 7, 0.5);
  }
  ${mobile(css`
  font-size: 40px;
  `)}
`;
const CurriWrap = styled.ul`
  width: 50%;
  list-style-type: none;
  padding: 0px;
  margin-top: 24px;
  ${mobile(css`
    width:100%;
  `)}
`;
const CurriTitle = styled.li`
  font-family: 'AppleSDGothicNeoR';
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.86px;
  color: #212121;
  margin-top: 12px;
  margin-bottom: 12px;
`;
const CurriDesc = styled.li`
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: -0.25px;
  -moz-letter-spacing: -0.25px;
  -ms-letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  color: #212121;
  font-weight: 600;
  font-family: 'AppleSDGothicNeoR';

`;
const CurriDetail = styled.div`
  font-family: 'AppleSDGothicNeoR';
  font-size: 19px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.01px;
  color: #c8c8c8;
  margin-bottom:3px;
`;
const Homework = styled.img`
  width: 830px;
  height: 748px;
  ${desktop(css`
    display: none;
  `)}
`;
const Space = styled.div`
  height: 100px;
  ${mobile(css`
    height: 50px;
  `)}
`;

const Curriculum = () => {
  return (
    <Wrap>
      <Flex>
        <Title>커리큘럼</Title>
        <FlexRow>
          <CurriWrap>
            <CurriTitle>소프트웨어과</CurriTitle>
            <CurriDesc>C언어</CurriDesc>
            <CurriDetail>
              프로그래밍의 기초인 C언어에 대해 배웁니다.
            </CurriDetail>
            <CurriDesc>콘솔게임</CurriDesc>
            <CurriDetail>
              C언어를 이용해 콘솔게임을 만드는 방법에 대해 배웁니다.
            </CurriDetail>
            <CurriDesc>Unity Engine</CurriDesc>
            <CurriDetail>
              대표적인 게임엔진 중 하나인 "Unity"를 사용하여 게임 만드는 방법에
              <br></br>
              대해 배웁니다.
            </CurriDetail>
            <CurriDesc>C++</CurriDesc>
            <CurriDetail>
              "ZeroEngine"을 사용하기 위한 언어인 C++에 대해 배웁니다.
            </CurriDetail>
            <CurriDesc>ZeroEngine</CurriDesc>
            <CurriDetail>
              동아리 자체엔진인 "ZeroEngine"을 다루는 법에 대해 배웁니다.
            </CurriDetail>
            <Space />
            <CurriTitle>콘텐츠디자인과</CurriTitle>
            <CurriDesc>게임디자인이론</CurriDesc>
            <CurriDetail>
              게임 디자인에는 무슨 디자인이 이용되는지에 대해 배웁니다.
            </CurriDetail>
            <CurriDesc>게임디자인</CurriDesc>
            <CurriDetail>
              게임 디자인에 사용되는 실루엣 , 컬러 , 애니메이팅에 대해 배웁니다.
            </CurriDetail>
            <CurriDesc>3D MAX</CurriDesc>
            <CurriDetail>
               3D MAX의 기본적인 사용 방법을 배웁니다.
            </CurriDetail>
          </CurriWrap>
          <Homework src={homework} alt="" />
        </FlexRow>
      </Flex>
    </Wrap>
  );
};

export default Curriculum;
